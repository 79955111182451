<template>
  <div class="excelImportContainer">
    <header>
      <div class="headerText">1、{{ $fanyi("请先下载Excel模板表格，再上传文件") }}。</div>
      <el-link :href="inventoryExcelUploadUrl+code" :underline="false" target="_blank">
        <div class="flexAndCenter">
          <img src="../../../../../assets/templatedownloadIcon.png" alt="">
          <div>{{ $fanyi("下载模板") }}</div>
        </div>
      </el-link>
    </header>
    <section>
      <div class="sectionTitle">2、{{ $fanyi("请选择上传文件") }}。</div>
      <div class="uploadContainer flexAndCenter">
        <div class="uploadHeaderContainer flexAndCenter">
          <el-upload
              class="upload-demo"
              :action="inventoryTplDownloadUrl"
              :show-file-list="false"
              :headers="headers"
              :on-success="handleAvatarSuccess"
              name="file"
              accept=".Xls,.Xlsx"
              :before-upload="beforeAvatarUpload">
            <img src="../../../../../assets/folderIcon.png" alt="">
            <div>{{ $fanyi("点击上传") }}</div>
          </el-upload>
        </div>
        <div class="uploadFooterContainer">
          <div>{{ $fanyi("文件格式仅限Xls和Xlsx") }}</div>
        </div>
      </div>
    </section>
    <div class="fontSize12 colorB4272B" style="margin-top: 12px">
      *{{ $fanyi("文件解析成功后，商品将直接在商品库里展示，重复的商品会按照文件编辑好的内容进行覆盖") }}。
    </div>
  </div>
</template>

<script>
export default {
  name: "excelImport",
  data() {
    return {
      uploadFileList: [],
      code: '',
      inventoryExcelUploadUrl: location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? 'https://apiwww.rakumart.com/client/favorite.inventoryTplDownload?rand=' : 'https://jpapi.rakumart.cn/client/favorite.inventoryTplDownload?rand=',
      inventoryTplDownloadUrl: location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? 'https://apiwww.rakumart.com/client/favorite.inventoryExcelUpload' : 'https://jpapi.rakumart.cn/client/favorite.inventoryExcelUpload',
      // inventoryExcelUploadUrl:'https://apiwww.rakumart.com/client/favorite.inventoryTplDownload?rand=',
      // inventoryTplDownloadUrl:'https://apiwww.rakumart.com/client/favorite.inventoryExcelUpload',
    }
  },
  computed: {
    headers() {
      return {
        "ClientToken": 'Bearer ' + localStorage.getItem('japan_user_token')
      }
    },
  },
  created() {
    for (var i = 0; i < 7; i++) {
      this.code += parseInt(Math.random() * 10);
    }
  },
  methods: {
    //文件上传前吹
    beforeAvatarUpload(file) {
      const isXls = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].indexOf(file.type) !== -1;
      if (!isXls) {
        this.$message.error(this.$fanyi("文件格式仅限Xls和Xlsx"));
        return isXls;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("文件上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.loading.close();
      if (res.code != 0) {
        this.$message.error(res.msg)
        return
      }
      this.$parent.$parent.excelImportDialogVisible = false;
      this.$parent.$parent.getData();
      this.$parent.$parent.getInventoryTableNum();
    },
    //删除文件
    deleteFileList(index) {
      this.uploadFileList.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.excelImportContainer {
  header {
    border-radius: 6px;
    border: 1px solid #DFDFDF;
    background: #F9F9F9;
    padding: 25px;
    margin-top: 26px;

    .headerText {
      font-size: 14px;
      margin-bottom: 19px;
      color: #333333;
    }

    .flexAndCenter {
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      padding-left: 20px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }

  section {
    border-radius: 6px;
    border: 1px solid #DFDFDF;
    background: #F9F9F9;
    padding: 25px;
    margin-top: 26px;

    .sectionTitle {
      margin-bottom: 20px;
    }

    .uploadContainer {
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 8px;
      padding: 25px 0;
      flex-direction: column;

      .uploadHeaderContainer {
        flex-direction: column;
        cursor: pointer;

        img {
          width: 64px;
          height: 50px;
          margin-bottom: 10px;
        }

        div {
          color: #B4272B;
          font-size: 14px;
        }
      }

      .uploadFooterContainer {
        color: #999999;
        font-size: 12px;
        margin-top: 26px;
      }
    }
  }

  .uploadFileContainer {
    margin-top: 32px;

    .fileContainer {
      margin-bottom: 21px;

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-left: 3px;
        cursor: pointer;
        margin-right: 12px;
        width: 150px;
        font-size: 14px;
      }
    }
  }

  footer {
    margin-top: 32px;
    margin-left: 47px;

    div {
      width: 120px;
      height: 34px;
      background: #FFFFFF;
      border: 1px solid #B4272B;
      border-radius: 8px;
      line-height: 34px;
      cursor: pointer;
      font-size: 14px;
      color: #B4272B;
      text-align: center;
    }

    div:first-child {
      margin-right: 26px;
    }

    div:last-child {
      color: #fff;
      background: #B4272B;
    }
  }
}
</style>

<template>
  <div>
    <!--          option编辑-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$fanyi('オプション')"
        :visible.sync="$parent.showGoodsOptionDialogVisible"
        append-to-body
        center
        custom-class="commodityWarehouseOptionDialogContainer"
        width="1375px"
        @opened="$forceUpdate()">
      <div class="optionSelectContainer">
        <div class="optionSelectHeaderContainer">
          <div class="optionSelectListContainer">
            <div class="optionArrContainer">
              <div v-for="(optionItem,optionIndex) in $parent.$parent.$parent.optionList" :key="optionIndex"
                   class="optionArrItemContainer">
                <div :class="optionItem.checked==true?'activeOptionArrItemNameContainer':'optionArrItemNameContainer'"
                     @click="updateOptionNum(optionItem)">
                  <div class="optionArrItemNameLeftContainer">
                    <div>{{ optionIndex + 1 }}</div>
                    <div>{{ optionItem.name_translate }}</div>
                  </div>
                  <div v-show="optionItem.checked==true" :style="optionItem.checked==true?'border:1px solid #B4272B':''"
                       class="checkedIcon">
                    <img alt="" src="../../../../../assets/optionCheckedIcon.png">
                  </div>
                  <div v-show="optionItem.checked==false" class="checkedIcon"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="optionInfo.price!=''" class="optionDetailContainer">
            <el-carousel
                :arrow="carouselList.length > 1 ? 'always' : 'never'"
                :autoplay="false"
                height="252px"
                indicator-position="none">
              <el-carousel-item v-for="(item, index) in carouselList" :key="index">
                <img v-show="item.type==='img'" :src="item.url" alt="" class="optionImage cursorPointer"
                     @click="imgFullscreen('.optionImage')">
                <div v-show="item.type!='img'"
                     class="edy-texteditor-container video embed-container edy-positionable-container-maxwidth"
                     contenteditable="false"
                     style="max-width: 100%;overflow: hidden;position: relative;width: auto; "
                     unselectable="on">
                  <div class="edy-padding-resizer-wrapper" style="padding-bottom: 56.25%">
                    <iframe id="ifrId" :src="item.url" allowfullscreen="allowfullscreen" frameborder="0" style="
                                              position: absolute;
                                              display: block;
                                              width: 100%;
                                              height: 100%;
                                            "></iframe>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="optionName">{{ optionInfo.name_translate }}</div>
            <div class="optionValue" v-html="optionInfo.desc"></div>
          </div>
        </div>
        <div class="optionSelectFooterContainer">
          <div>
            <div class="color999">※ オプションを選択してから数量が自動的に記入します。</div>
            <div class="color999">
              Rakumartの各サービスのオプション料金を確認するためには、
              <router-link :to="{ path: '/option'}"
                           class="colorB4272B cursorPointer"
                           target="_blank">
                オプション費用
              </router-link>
              をクリックしてください。
            </div>
          </div>
          <div class="optionOperationBtnContainer">
            <el-button @click="resetGoodsOption">{{ $fanyi("重置") }}</el-button>
            <el-button type="primary" @click="saveGoodsOption">{{ $fanyi("保存") }}</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      newOptionPrice: this.optionPrice,
      carouselList: [],
      optionInfo: {
        desc: '',
        price: '',
        unit: '',
        image: '',
      },
    }
  },
  methods: {
    //重置商品的附加服务
    resetGoodsOption() {
      this.$parent.$parent.$parent.optionList.forEach((item) => {
        item.checked = false;
      })
    },
    //保存商品的附加服务
    saveGoodsOption() {
      this.$parent.showGoodsOptionDialogVisible = false;
    },
    //防止输入框滚动增值
    stopScroll(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    //输入框校验
    newChannelInputLimit(e) {
      let key = e.key;
      // 不允许输入'e'、'.'、'。'、'-'
      if (key === "e" || key === "." || key === "。" || key == "-" || key == "-" || key == "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    imgFullscreen(val) {
      const el = document.querySelector(val); // 获取需要全屏的元素
      if (!document.fullscreenElement) { // 判断是否已经处于全屏模式
        el.requestFullscreen().catch(err => { // 进入全屏模式
          console.log(`进入全屏模式失败：${err.message}`);
        });
      } else {
        document.exitFullscreen(); // 退出全屏模式
      }
    },
    //根据当前订单的番数更新option选中的数量
    updateOptionNum(item) {
      item.checked = !item.checked;
      item.num = 1;
      if (item.checked) {
        this.optionInfo = item;
      }
      this.carouselList = [];
      if (item.video != '') {
        if (item.image != '') {
          this.$nextTick(() => {
            this.carouselList.push({
              url: item.image,
              type: 'img'
            })
          })
        }
        this.$nextTick(() => {
          this.carouselList.push({
            url: item.video,
            type: 'video'
          })
        })
      } else {
        this.$nextTick(() => {
          this.carouselList.push({
            url: item.image != '' ? item.image : require('../../../../../assets/optionInPreparationImage.jpg'),
            type: 'img'
          })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
<template>
  <div id="userOrder">
    <HomePageTop ref="tyop"></HomePageTop>
    <!--      顶部文章与筛选项-->
    <div class="collectionContainer">
      <div class="head">
        <span><span class="headT1">{{ $fanyi('商品库') }}</span></span>
        <div class="headerSearchContainer flexAndCenter">
          <el-input v-model="from.keywords" :placeholder="$fanyi('请输入想搜索的URL')" @input="keywordsChange"
                    style="width:200px;margin-right: 8px"/>
          <el-input v-model="from.for_sn" :placeholder="$fanyi('管理番号')" @input="forSnChange"
                    style="width:200px;margin-right: 8px"/>
          <el-input v-model="from.goods_tag" :placeholder="$fanyi('标签搜索')" @input="tagChange"
                    style="width:200px;margin-right: 8px"/>
          <div class="searchBtn" @click="getData()">{{ $fanyi('搜索') }}</div>
        </div>
      </div>
      <!--      状态切换与操作按钮-->
      <div class="flex orderScreenContainer">
        <div class="flexAndCenter">
          <div class="orderScreenLeft flexAndCenter">
            <div v-for="(item,index) in addressSwitchover" :key="index"
                 :class="switchoverIndex===index?'activeSwitchover':''"
                 :style="item.goods_status==1?'background-color: rgba(0, 0, 0, 0.2);':''"
                 @click="switchoverIndex=index;from.goods_status=item.value;from.page=1;$forceUpdate;getData()">
              {{ item.label }}
            </div>
          </div>
          <el-popover trigger="hover">
            <div>{{ from.order_by == 'desc' ? '降順から昇順に切り替わります。' : '昇順から降順に切り替わります。' }}</div>
            <img slot="reference" alt="" class="sortIcon" src="../../../../assets/icon/sort.png"
                 @click="updateGoodsSort">
          </el-popover>
        </div>
        <div class="orderScreenBtnListContainer flexAndCenter">
          <div @click="deleteGoods('all','')">{{ $fanyi('删除') }}</div>
          <div @click="excelImportDialogVisible=true;status=true">{{ $fanyi('Excel导入') }}</div>
          <div @click="addGoods=true;status=true;editGoodsDialogVisible=true">{{ $fanyi('追加') }}</div>
          <div style="margin-right: 0" @click="placeAnOrder">{{ $fanyi('提出订单') }}</div>
        </div>
      </div>
      <div class="flex tagMessageContainer" :style="tagList.length>0?'paddingBottom:14px':'paddingBottom:26px'">
        <!--      标签名搜索-->
        <div class="tagMessageRightContainer flexAndCenter">
          <el-input :placeholder="$fanyi('请输入标签名')" v-model="tagValue">
            <template slot="append">
              <div @click="addTag">{{ $fanyi('新增') }}</div>
            </template>
          </el-input>
        </div>
        <div class="tagMessageLeftContainer flex">
          <div class="tagListContainer">
            <!--            :style="tagInfo.id==item.id?'height:42px':''"-->
            <div v-for="(item,index) in tagList" :key="index"
                 :style="from.tag_ids.lastIndexOf(item.id)!==-1?'background: #fff;color: #B4272B;border: 1px solid #B4272B;':''"
                 :class="tagInfo.id!==item.id?'tagContainer':'newTagContainer'"
                 @click="updateTagId(item);$forceUpdate();">
              <span v-if="tagInfo.id!==item.id">{{ item.tag_name }}</span>
              <el-input v-else v-model="item.tag_name" size="mini" style="width: 100px"
                        @blur="updateTagName(item)"/>
              <img src="../../../../assets/redEditIcon.png" style="margin-left: 8px"
                   @click.stop="getEditTagInfo(item)"/>
              <div class="deleteTagContainer" @click.stop="deleteIcon(item)">x</div>
            </div>
          </div>
        </div>
      </div>
      <!--      商品库表格-->
      <div class="table">
        <ul class="tableHeader flexAndCenter">
          <li class="checkAll flexAndCenter">
            <input type="checkbox" style="margin-right:6px" @change="isAllChecked" v-model="checked"/>
            <div>{{ $fanyi("全选") }}</div>
          </li>
          <li class="number">{{ $fanyi("番号") }}</li>
          <li class="managementNumber">{{ $fanyi("管理番号") }}</li>
          <li class="mirror">{{ $fanyi("写真") }}</li>
          <li class="goodsAttribute">{{ $fanyi("商品属性") }}</li>
          <li class="price">{{ $fanyi("单价（元）") }}</li>
          <li class="goodsAttribute">option</li>
          <li class="tagSetting">{{ $fanyi("标签设置") }}</li>
          <li class="remark">{{ $fanyi("備考欄") }}</li>
          <li class="operation">{{ $fanyi("操作") }}</li>
        </ul>
        <transition-group ref="eventConfig" class="goodsBox" tag="div">
          <ul v-for="(item,index) in lists" :key="item.id" :draggable="isDraggable"
              class="tableFooter flexAndCenter"
              :class="dragging==item?'dragging':''"
              :style="item.goods_status==1?'background-color: rgba(0, 0, 0, 0.2);':''">
            <!--            全选-->
            <li class="checkAll flexAndCenter" style="position: relative">
              <el-popover trigger="hover">
                <div>押したまま移動させることで順番の変更が可能です。</div>
                <img slot="reference" alt=""
                     class="moveIcon cursorPointer"
                     src="../../../../assets/moveIcon.svg"
                     @dragend="handleDragEnd($event, item,index)"
                     @dragenter="handleDragEnter($event, item,index)"
                     @dragstart="handleDragStart($event, item)" @dragover.prevent="handleDragOver($event, item)">
              </el-popover>
              <!--      标签操作-->
              <el-popover trigger="hover" popper-class="inventoryTags">
                <div class="tagSelectContainer" @mouseleave="goodsTagsMouseleave(item)">
                  <div class="tagSelectHeader" :style="tagList.length==0?'margin-bottom:0':''">{{
                      $fanyi('标签选择')
                    }}：
                  </div>
                  <div class="tagItemListContainer">
                    <div v-for="(tagItem,tagIndex) in tagList" :key="tagIndex" class="tagItem flexAndCenter">
                      <input type="checkbox" style="margin-left: 0" v-model="tagItem.checked"/>
                      <div>{{ tagItem.tag_name }}</div>
                    </div>
                  </div>
                </div>
                <img src="../../../../assets/editTagIcon.png" slot="reference"
                     style="margin-right: 8px;margin-top:59px;width:20px" @mouseenter="goodsTagsMouseenter(item)">
              </el-popover>
              <div class="cursorPointer" style="min-height: 120px;display: flex;align-items:center"
                   @click="item.checked=!item.checked;itemIsAllChecked(item)">
                <input v-model="item.checked" style="margin-right:6px"
                       type="checkbox"
                       @change.stop="itemIsAllChecked(item)"/>
              </div>
            </li>
            <!--            番号-->
            <li class="number">{{ index + 1 }}</li>
            <!--            管理番号-->
            <li :title="item.for_sn" class="managementNumber cursorPointer" @click="getEditSnInfo(item)">
              <div v-if="item.id!=snInfo.id" class="forSnText u-line-3">
                {{ item.for_sn }}
              </div>
              <el-input v-else v-model="item.for_sn" size="mini" @blur="updateForSn(item)"/>
            </li>
            <!--            写真-->
            <li :style="item.goods_status==1?'position: relative':''" class="mirror">
              <el-popover placement="right" trigger="hover">
                <el-image :src="item.img_url"
                          style="width: 300px; height: 300px"/>
                <el-image :src="item.img_url"
                          slot="reference"
                          @click="$fun.toCommodityDetails(item.goods_id,item.shop_type)"
                          style="width: 60px;height: 60px;margin-top: 50px;cursor: pointer"/>
              </el-popover>
              <div v-if="item.goods_status==1" class="soldOutIcon">販売終了</div>
            </li>
            <!--            商品属性-->
            <li class="goodsAttribute">
              <div class="attributeContainer" v-if="item.detail_edit!=null&&item.detail_edit.length===0">
                <el-popover placement="bottom" trigger="hover">
                  <div v-for="(detailItem,detailIndex) in item.detail" :key="detailIndex">
                    {{ detailItem.key }}：{{ detailItem.value }}
                  </div>
                  <div slot="reference" v-for="(detailItem,detailIndex) in item.detail" :key="detailIndex">
                    {{ detailItem.key }}：{{ detailItem.value }}
                  </div>
                </el-popover>
              </div>
              <div class="attributeContainer" v-else>
                <el-popover placement="bottom" trigger="hover">
                  <div v-for="(detailItem,detailIndex) in item.detail_edit" :key="detailIndex">
                    {{ detailItem.key }}：{{ detailItem.value }}
                  </div>
                  <div slot="reference" v-for="(detailItem,detailIndex) in item.detail_edit" :key="detailIndex"
                       class="u-line" style="width:200px">
                    {{ detailItem.key }}：{{ detailItem.value }}
                  </div>
                </el-popover>
              </div>
            </li>
            <!--            单价（元）-->
            <li class="price">{{ item.price }}</li>
            <!--            option-->
            <li class="goodsAttribute">
              <el-popover trigger="hover">
                <div v-show="item.option!=='[]'" class="newGoodsAttributeList">
                  <div v-for="(item,index) in item.option" :key="index">
                    {{ item.sort }}.{{ item.name_translate }}
                  </div>
                </div>
                <div slot="reference" style="height: 80px">
                  <div v-show="item.option!=='[]'" class="goodsAttributeList">
                    <div v-for="(item,index) in item.option" :key="index">
                      {{ item.sort }}.{{ item.name_translate }}
                    </div>
                  </div>
                  <div v-show="item.optionCheckedNum>3" class="ellipsisText">........</div>
                </div>
              </el-popover>
            </li>
            <!--            标签设置-->
            <li class="tagSetting">
              <el-popover trigger="hover">
                <div v-for="(tagItem,tagIndex) in item.inventory_tag" style="line-height: 20px" :key="tagIndex">
                  <div v-show="tagItem.no!==''||tagItem.goods_no!==''">
                    <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                    <div class="color333 fontSize14">{{ tagItem.no }}</div>
                    <div class="color333 fontSize14">{{ tagItem.goods_no }}</div>
                  </div>
                </div>
                <div slot="reference">
                  <div v-for="(tagItem,tagIndex) in item.inventory_tag"
                       :key="tagIndex"
                       style="line-height: 20px;">
                    <div v-show="tagItem.no!==''||tagItem.goods_no!==''" style="width: 100px;text-align:left">
                      <div class="color333 fontSize14">{{ tagItem.type_translate }}</div>
                      <div class="color333 fontSize14 u-line-2">{{ tagItem.no }}</div>
                      <div class="color333 fontSize14 u-line-2">{{ tagItem.goods_no }}</div>
                    </div>
                  </div>
                </div>
              </el-popover>
            </li>
            <!--            備考欄-->
            <li class="remark" style="height: 100px;">
              <!--              {{-->
              <!--                item.note-->
              <!--              }}-->
              <textarea v-model="item.note" @blur="updateInventoryRemark(item)"></textarea>
            </li>
            <!--            操作-->
            <li class="operation">
              <div class="btnList flexAndCenter">
                <div @click="deleteGoods('noAll',item)">{{ $fanyi("删除") }}</div>
                <div @click="editGoods(item);addGoods=false;status=true">{{ $fanyi("编辑") }}</div>
                <div @click="copy(item)">{{ $fanyi("复制") }}</div>
              </div>
            </li>
          </ul>
        </transition-group>
      </div>
      <div style="padding: 30px 0;justify-content: center" class="flex pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="from.page"
            :page-sizes="[100]"
            :page-size="from.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
    <!--    弹窗-->
    <div class="dialogContainer" v-if="status">
      <el-dialog
          width="400px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :title="$fanyi('Excel导入')"
          :visible.sync="excelImportDialogVisible">
        <excelImport @closeExcelImportDialogVisible="closeExcelImportDialogVisible"/>
      </el-dialog>
      <el-dialog
          width="800px"
          :title="addGoods==true?$fanyi('追加'):$fanyi('编辑')"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          @close="deleteDialogVisibleData"
          :visible.sync="editGoodsDialogVisible">
        <editGoods ref="editGoods" @closeEditGoodsDialogVisible="closeEditGoodsDialogVisible"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../../../components/head/HomePageTop.vue";
import excelImport from "@/views/UserCenter/views/collection/components/excelImport";
import editGoods from '@/views/UserCenter/views/collection/components/editGoods'

export default {
  data() {
    return {
      excelImportDialogVisible: false,
      editGoodsDialogVisible: false,
      addGoods: false,
      goodsList: [], //收藏商品列表
      loading: true,
      status: false,
      checked: false,
      goodIndex: null,
      tagList: [],//标签列表
      lists: [],
      dragging: null,
      draggingStatus: false,
      from: {
        goods_status: undefined,
        order_by: 'desc',
        page: 1,
        pageSize: 100,
        keywords: '',
        for_sn: '',
        tag_ids: '',
        goods_tag: ''
      },
      tagInfo: {},
      snInfo: {
        id: ''
      },
      addressSwitchover: [{
        label: this.$fanyi("全部商品") + '（0）',
        value: undefined
      }, {
        label: this.$fanyi("失效") + '（0）',
        value: '已下架'
      }],
      isDraggable: true,
      checkedList: [],
      tagValue: '',
      optionList: [],
      goodsInfo: {},
      switchoverIndex: 0,
      inventoryTagOption: [],
      total: 0,
    };
  },
  components: {
    HomePageTop,
    excelImport,
    editGoods
  },
  computed: {},
  created() {
    this.getData();
    this.getInventoryTableNum();
    this.$api.optionList({
      need_desc: true
    }).then((res) => {
      res.data.forEach((item) => {
        item.checked = false;
      })
      this.optionList = res.data;
    });
    this.$api.getOrderTagList({}).then((res) => {
      res.data.forEach((item) => {
        item.no = '';
        item.goods_no = ''
      })
      this.inventoryTagOption = res.data;
    });
    if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
      this.isDraggable = false;
    }
    this.getTags();
  },
  methods: {
    //管理番号变更
    forSnChange(e) {
      this.from.for_sn = e;
    },
    //链接变更
    keywordsChange(e) {
      this.from.keywords = e;
    },
    //标签搜索变更
    tagChange(e) {
      this.from.goods_tag = e;
    },
    //获取商品库各个状态下的数量
    getInventoryTableNum() {
      this.$api.inventoryTableNum().then((res) => {
        res.data.forEach((item) => {
          switch (item.name) {
            case '上架中':
              this.addressSwitchover[0].label = this.$fanyi("全部商品") + `（${item.count}）`;
              break;
            case '已下架':
              this.addressSwitchover[1].label = this.$fanyi("失效") + `（${item.count}）`;
              break;
          }
        })
      });
    },
    //更新标签的名称
    updateTagName(val) {
      if (val.tag_name !== '') {
        this.$api.tagNameEdit({
          tag_id: val.id,
          tag_name: val.tag_name
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.from.tag_ids = '';
          this.getTags();
          this.from.page = 1;
          this.tagInfo = {};
          this.getData();
        }).catch((err) => {
        });
      }
    },
    // 添加商品标签
    async addTag(item) {
      this.$api.favoriteTagAdd({
        type: 3,
        tag_name: this.tagValue,
      }).then((res) => {
        if (res.code != 0) return;
        this.tagValue = "";
        // 重新获取标签列表
        this.getTags();
      });
    },
    getTags() {
      this.$api.favoriteTagList({type: "3"}).then((res) => {
        this.tagList = res.data;
        // 调用处理标签列表事件
        this.tagDataProcessing();
        this.$forceUpdate();
      });
    },
    //订单全选
    isAllChecked() {
      this.lists.forEach((item) => {
        item.checked = this.checked
      })
    },
    //更新列表排序状态
    updateGoodsSort() {
      this.from.order_by === 'asc' ? this.from.order_by = 'desc' : this.from.order_by = 'asc';
      this.getData();
      this.getInventoryTableNum();
    },
    updateTagId(val) {
      if (this.from.tag_ids === '') {
        this.from.tag_ids = val.id
      } else if (this.from.tag_ids !== '' && this.from.tag_ids.indexOf(val.id) === -1) {
        this.from.tag_ids += ',' + val.id
      } else if (this.from.tag_ids !== '' && this.from.tag_ids.indexOf(val.id) !== -1) {
        let index = this.from.tag_ids.indexOf(val.id);
        let target = '';
        if (index === 0) {
          target = val.id;
        } else {
          target = ',' + val.id;
        }
        this.from.tag_ids = this.from.tag_ids.replace(target, "");
      }
      this.getData();
    },
    //获取要编辑的tag信息
    getEditTagInfo(val) {
      this.tagInfo = this.$fun.deepClone(val);
      this.$forceUpdate();
    },
    updateInventoryRemark(item) {
      if (item.note != '') {
        this.favoriteInventoryBatchSave(item)
      }
    },
    favoriteInventoryBatchSave(item) {
      let arr = [];
      let inventory_tag = [];
      item.inventory_tag.forEach((tagItem) => {
        if (tagItem.goods_no !== '' || tagItem.no !== '') {
          inventory_tag.push({
            type: tagItem.type,
            no: tagItem.no,
            goods_no: tagItem.goods_no,
            type_translate: tagItem.type_translate
          })
        }
      })
      arr.push({
        id: item.id,//判断是否是商品库追加
        shop_type: item.shop_type,
        shop_id: item.shop_id,
        goods_id: item.goods_id,
        num: item.num,
        price: item.price,
        sku_id: item.sku_id,
        spec_id: item.spec_id,
        shop_name: item.shop_name,
        goods_title: item.goods_title,
        img_url: item.img_url,
        detail: JSON.stringify(item.detail),
        option: item.option.length > 0 ? JSON.stringify(item.option) : '[]',
        note: item.note,
        for_sn: item.for_sn,
        detail_edit: item.detail_edit.length > 0 ? JSON.stringify(item.detail_edit) : undefined,
        inventory_tag: inventory_tag
      })
      this.$api.favoriteInventoryBatchSave({data: arr}).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
      });
    },
    //单番选中是否全选
    itemIsAllChecked(val) {
      if (val.checked == false) {
        this.checkedList.forEach((item, index) => {
          if (val.id == item) {
            this.checkedList.splice(index, 1)
          }
        })
      }
      let count = 0;
      this.lists.forEach((item) => {
        if (item.checked === true) {
          count++
        }
        this.checked = count === this.lists.length;
      })
    },
    //关闭excel导入弹窗
    closeExcelImportDialogVisible(msg) {
      this.excelImportDialogVisible = false;
    },
    //新增或编辑商品库信息
    closeEditGoodsDialogVisible() {
      this.editGoodsDialogVisible = false;
      this.getData();
      this.getInventoryTableNum();
      this.goodIndex = null;
      this.status = false;
      this.$refs.editGoods.url = '';
    },
    //查看tag鼠标移出
    goodsTagsMouseleave(item) {
      let tagIds = '';
      this.tagList.forEach((tagItem) => {
        if (tagItem.checked === true) {
          tagIds += tagItem.id + ','
        }
      })
      tagIds = tagIds.substr(0, tagIds.length - 1);
      this.$api.inventoryGoodsToTags({
        id: item.id,
        tag_ids: tagIds
      }).then((res) => {
        if (res.code != 0) return this.$message.error(res.msg);
        this.getData(this.from);
      })
    },
    goodsTagsMouseenter(item) {
      this.tagList.forEach((tagItem) => {
        tagItem.checked = false;
      })
      let tagIds = [];
      if (item.tag_ids !== '') {
        tagIds = item.tag_ids.split(',');
        this.tagList.forEach((tagItem) => {
          tagIds.forEach((tagIdsItem) => {
            if (tagItem.id == tagIdsItem) {
              tagItem.checked = true
            }
          })
        })
      }
    },
    handleDragStart(e, item) {
      this.dragging = item;
      this.draggingStatus = true;
    },
    handleDragEnd(e, item, index) {
      this.dragging = null
      let data = {
        id: item.id,
        preId: this.lists[index - 1] == undefined ? undefined : this.lists[index - 1].id,
        nextId: this.lists[index + 1] == undefined ? undefined : this.lists[index + 1].id,
        order_by: this.from.order_by
      }
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('商品库拖动排序次数');
      }
      this.$api.inventoryDragSorting(data).then((res) => {
        if (res.code != 0) return;
      })
    },
    handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move'
      // 获取拖拽元素在浏览器中的y坐标，即高度位置
      const disY = e.clientY;
      // 从拖拽元素的父元素开始找滚动条
      if (disY < 200) {
        let y = this.$fun.deepClone(disY);
        if (y > 0) {
          document.documentElement.scrollTop = document.documentElement.scrollTop - 5;
        }
      } else if (disY > 700) {
        // 返回滚动条垂直滚动距离
        let scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
        let clientHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
        // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
        let scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
        let numHeight = scrollTop + clientHeight;
        if (scrollHeight > numHeight) {
          document.documentElement.scrollTop = document.documentElement.scrollTop + 5;
        }
      }
    },
    handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move'
      if (item === this.dragging) {
        return
      }
      const newItems = [...this.lists]
      const src = newItems.indexOf(this.dragging)
      const dst = newItems.indexOf(item)
      newItems.splice(dst, 0, ...newItems.splice(src, 1))
      this.lists = newItems
    },
    //获取要编辑的管理番号详情
    getEditSnInfo(val) {
      this.snInfo = val;
    },
    updateForSn(item) {
      if (item.for_sn != '') {
        this.favoriteInventoryBatchSave(item)
      }
    },
    //删除商品库商品
    deleteGoods(type, item) {
      let arr = [];
      if (type !== 'all') {
        arr.push(item.id)
      } else {
        this.lists.forEach((listsItem) => {
          if (listsItem.checked === true) {
            arr.push(listsItem.id);
          }
        })
      }
      if (arr.length === 0) {
        this.$message.error(this.$fanyi('请至少选择一个商品'));
        return
      }
      this.$confirm(
          this.$fanyi("确认删除选中的商品吗？"),
          this.$fanyi("提示"),
          {
            confirmButtonText: this.$fanyi("确定"),
            cancelButtonText: this.$fanyi("取消"),
            type: "warning",
            center: true,
          }).then(() => {
        this.$api.favoriteInventoryDelete({id: arr}).then((res) => {
          if (res.code != 0) return;
          if (this.checked == true) {
            this.checked = false;
          }
          this.getData();
          this.getInventoryTableNum();
          this.$forceUpdate();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$fanyi("已取消删除")
        });
      });
    },
    //复制商品库商品
    copy(item) {
      this.status = true;
      this.addGoods = true;
      this.goodIndex = item.id;
      if (item.option.length > 0) {
        this.optionList.forEach((oldOption) => {
          item.option.forEach((goodsOption) => {
            if (oldOption.id === goodsOption.id) {
              oldOption.checked = true;
            }
          })
        })
      }
      this.editGoodsDialogVisible = true
      if (item.goods_id == '') {
        this.$message.error(this.$fanyi("该商品因库存不足或其他原因已经找不到，请查看其他商品！"));
        return
      }
      this.goodsInfo = {
        for_sn: item.for_sn,
        note: item.note,
        num: item.num,
        img_url: item.img_url,
        shop_name: item.shop_name,
        goods_title: item.goods_title,
        shop_type: item.shop_type,
        shop_id: item.shop_id,
        goods_id: item.goods_id,
        detail_edit: item.detail_edit,
        price: item.price
      }
      let tagValue = '';
      let inventoryTagOption = this.$fun.deepClone(this.inventoryTagOption);
      item.inventory_tag.forEach((item) => {
        inventoryTagOption.forEach((optionItem) => {
          if (optionItem.type === item.type) {
            tagValue = item.type;
            optionItem.no = item.no;
            optionItem.goods_no = item.goods_no;
          }
        })
      })
      if (item.goodsInfo == undefined) {
        this.$api.getGoodsAttribute({
          shop_type: item.shop_type,
          goods_id: item.goods_id
        }).then((res) => {
          if (res.data[0].goodsInventory.length > 0 && res.data[0].specification.length > 0) {
            item.goodsInfo = {
              goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
              specification: this.$fun.deepClone(res.data[0].specification),
            }
            if (res.data[0].specification.length > 0) {
              item.goodsInfo.specification.forEach((specificationItem) => {
                specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                  item.detail.forEach((detailItem) => {
                    if (specificationItem.keyC === detailItem.key && valueCItem.name === detailItem.value) {
                      specificationItem.active = valueCIndex;
                    }
                  })
                })
              })
            }
          } else {
            item.goodsInfo = {
              goodsInventory: [],
              specification: [],
            }
            item.detail.forEach((detailItem) => {
              item.goodsInfo.specification.push({
                keyC: detailItem.key,
                keyT: detailItem.key,
                active: 0,
                valueC: [{name: detailItem.value, picUrl: ''}],
                valueT: [{name: detailItem.value, picUrl: ''}]
              })
            })
          }
          this.goodsInfo = this.$fun.deepClone(item);
          this.editGoodsDialogVisible = true;
          setTimeout(() => {
            this.$refs.editGoods.artificialAttributeEditList = item.detail_edit;
            if (item.detail_edit.length > 0) {
              this.$refs.editGoods.attributeTypeStatus = 2;
            }
          }, 0)
        })
      } else {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem) => {
            specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
              item.detail.forEach((detailItem) => {
                if (specificationItem.keyC === detailItem.key && valueCItem.name === detailItem.value) {
                  specificationItem.active = valueCIndex;
                }
              })
            })
          })
        }
        this.goodsInfo = this.$fun.deepClone(item);
        this.editGoodsDialogVisible = true;
        setTimeout(() => {
          this.$refs.editGoods.artificialAttributeEditList = item.detail_edit;
          if (item.detail_edit.length > 0) {
            this.$refs.editGoods.attributeTypeStatus = 2;
          }
        }, 0)
      }
      setTimeout(() => {
        this.$refs.editGoods.inventoryTagOption = inventoryTagOption;
        this.$refs.editGoods.tagValue = tagValue;
      }, 1000)
    },
    //编辑商品库商品
    editGoods(item) {
      if (item.option.length > 0) {
        this.optionList.forEach((oldOption) => {
          item.option.forEach((goodsOption) => {
            if (oldOption.id === goodsOption.id) {
              oldOption.checked = true;
            }
          })
        })
      }
      if (item.goods_id == '') {
        this.$message.error(this.$fanyi("该商品因库存不足或其他原因已经找不到，请查看其他商品！"));
        return
      }
      if (item.goodsInfo == undefined) {
        this.$api.getGoodsAttribute({
          shop_type: item.shop_type,
          goods_id: item.goods_id
        }).then((res) => {
          if (res.data[0].goodsInventory.length > 0 && res.data[0].specification.length > 0) {
            item.goodsInfo = {
              goodsInventory: this.$fun.deepClone(res.data[0].goodsInventory),
              specification: this.$fun.deepClone(res.data[0].specification),
            }
            if (res.data[0].specification.length > 0) {
              item.goodsInfo.specification.forEach((specificationItem) => {
                specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
                  item.detail.forEach((detailItem) => {
                    if (specificationItem.keyC === detailItem.key && valueCItem.name === detailItem.value) {
                      specificationItem.active = valueCIndex;
                    }
                  })
                })
              })
            }
          } else {
            item.goodsInfo = {
              goodsInventory: [],
              specification: [],
            }
            item.detail.forEach((detailItem) => {
              item.goodsInfo.specification.push({
                keyC: detailItem.key,
                keyT: detailItem.key,
                active: 0,
                valueC: [{name: detailItem.value, picUrl: ''}],
                valueT: [{name: detailItem.value, picUrl: ''}]
              })
            })
          }
          this.goodsInfo = this.$fun.deepClone(item);
          this.editGoodsDialogVisible = true;
          setTimeout(() => {
            this.$refs.editGoods.artificialAttributeEditList = item.detail_edit;
            if (item.detail_edit.length > 0) {
              this.$refs.editGoods.attributeTypeStatus = 2;
            }
          }, 0)
        })
      } else {
        if (item.goodsInfo.specification.length > 0) {
          item.goodsInfo.specification.forEach((specificationItem) => {
            specificationItem.valueC.forEach((valueCItem, valueCIndex) => {
              item.detail.forEach((detailItem) => {
                if (specificationItem.keyC === detailItem.key && valueCItem.name === detailItem.value) {
                  specificationItem.active = valueCIndex;
                }
              })
            })
          })
        }
        this.goodsInfo = this.$fun.deepClone(item);
        this.editGoodsDialogVisible = true;
        setTimeout(() => {
          this.$refs.editGoods.artificialAttributeEditList = item.detail_edit;
          if (item.detail_edit.length > 0) {
            this.$refs.editGoods.attributeTypeStatus = 2;
          }
        }, 0)
      }
    },
    deleteIcon(item) {
      this.$confirm(
          `削除したタグ名は元に戻すことは出来ません。<br>「${item.tag_name}」タグ名を削除してもよろしいでしょうか。`,
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.favoriteTagDelete({
          tag_id: item.id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(res.msg);
          this.$message.success(res.msg);
          this.from.tag_ids = '';
          this.getTags();
          this.getData(this.from)
        })
      }).catch(() => {

      });
    },
    // 全局鼠标点击事件
    bodyCloseMenus() {
      this.goodsList.forEach((item) => {
        item.showTagWin = false;
      });
      this.$forceUpdate();
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getData() {
      this.lists.forEach((item) => {
        if (item.checked) {
          this.checkedList.push(item.id)
        }
      })
      this.checkedList = this.$fun.unique(this.checkedList);
      this.$api.favoriteInventoryList(this.from).then((res) => {
        res.data.data.forEach((item) => {
          item.optionCheckedNum = 0;
          this.checkedList.indexOf(item.id) == -1 ? item.checked = false : item.checked = true;
          item.option.forEach((optionItem) => {
            if (optionItem.num > 0) {
              item.optionCheckedNum++
            }
          })
        })
        this.lists = res.data.data;
        this.total = res.data.total;
      });
    },
    //提出订单
    placeAnOrder() {
      let arr = this.checkedList;
      this.lists.forEach((item) => {
        if (item.checked === true) {
          arr.push(item.id)
        }
      })
      if (arr.length > 100) {
        return this.$message.warning(this.$fanyi("一个订单最多添加100个商品"));
      }
      if (arr.length === 0) {
        return this.$message.warning(this.$fanyi("请选择要提出订单的商品"));
      }
      if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
        __bl.sum('通过商品库下单的商品个数统计', arr.length);
      }
      arr = this.$fun.unique(arr);
      let id = arr.join(",");
      let url = `/OrderDetails?id=${id}&type=temporary&iscomm=true`;
      window.open(url);
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagList.forEach((item) => {
        item.checked = false;
        item.titleChecked = false;
        item.id = item.id + ''
      });
    },
    // 处理收藏夹商品数据
    dataProcessing() {
      if (this.goodsList != undefined) {
        this.goodsList.forEach((item) => {
          item.showTagWin = false;
          item.checked = false;
        });
      }
    },
    handleSizeChange(val) {
      this.from.pageSize = val;
      this.getData();
    },
    //删除
    deleteDialogVisibleData() {
      this.goodsInfo = {};
      this.$refs.editGoods.url = '';
      this.optionList.forEach((item) => {
        item.checked = false;
      })
      this.goodIndex = null;
      this.status = false;
    },
    //下载账单excel
    downloadExcel() {
      this.$api.getInventoryDownloadToken().then((res) => {
        const a = document.createElement('a');
        let baseURL = location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1 ? 'https://apiwww.rakumart.com' : 'https://jpapi.rakumart.cn';
        a.href = `${baseURL}/client/download.inventoryDownload?user_id=${this.$store.state.userInfo.operation_id}&downloadToken=${res.data.downloadToken}`;
        a.target = '_blank';
        document.body.appendChild(a)
        a.click()
      });
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./css/collection.scss";

.dragging {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
</style>

<template>
  <div class="editGoodsContainer">
    <div class="borderBottom">
      <!--    商品链接-->
      <div class="flexAndCenter margin-bottom-20 goodsLink">
        <div class="label margin-right-35">{{ $fanyi("商品链接") }}:</div>
        <el-input v-model="url" @blur="getProductDetails"
                  style="width: 500px"
                  :placeholder="$fanyi('请输入1688链接或taobao链接或RAKUMART链接')"/>
      </div>
      <!--      写真-->
      <div class="flex margin-bottom-20">
        <div class="label margin-right-35" style="width: 60px">{{ $fanyi("写真") }}:</div>
        <div class="goodsImage flexAndCenter">
          <el-upload class="upload-demo"
                     :action="uploadUrl"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     name="file"
                     slot="reference"
                     accept=".jpg,.jpeg,.png"
                     :before-upload="beforeAvatarUpload">
            <el-popover placement="right" trigger="hover">
              <img :src="$parent.$parent.goodsInfo.img_url"
                   @click="productDetailsPageJump"
                   style="width: 300px; height: 300px"/>
              <img :src="$parent.$parent.goodsInfo.img_url"
                   slot="reference"
                   style="width: 100px;height: 100px"/>
            </el-popover>
          </el-upload>
          <div class="flexAndCenter updateGoodsImageIcon">
            <img src="../../../../../assets/baiEditIcon.png" style="width:12px;height:12px">
          </div>
        </div>
      </div>
      <!--      单价.元-->
      <div class="flex margin-bottom-20 goodPrice">
        <div class="label margin-right-35">{{ $fanyi("单价.元") }}:</div>
        <el-input v-model="$parent.$parent.goodsInfo.price" size="mini"/>
      </div>
    </div>
    <!--    网站属性选择-->
    <div class="borderBottom padding-bottom-20">
      <div class="goodsAttributeEditTypeContainer">
        <el-button size="mini" :style="attributeTypeStatus===1?'background:#c35255;color:#fff':'background:#fff'"
                   @click="attributeTypeStatus=1">
          {{ $fanyi('网站属性选择') }}
        </el-button>
        <el-button size="mini" :style="attributeTypeStatus===2?'background:#c35255;color:#fff':'background:#fff'"
                   @click="attributeTypeStatus=2">{{ $fanyi('人工编辑属性') }}
        </el-button>
      </div>
      <div class="AttributeListContainer" v-show="attributeTypeStatus===1">
        <div v-if="JSON.stringify($parent.$parent.goodsInfo)!=='{}'&&$parent.$parent.goodsInfo.goodsInfo!=undefined">
          <div v-for="(item,index) in $parent.$parent.goodsInfo.goodsInfo.specification"
               :key="index" class="goodsDetailContainer flex">
            <div class="keyContainer">{{ item.keyT }}:</div>
            <div class="valueAttributeListContainer">
              <div v-for="(items,valueIndex) in item.valueC" :key="valueIndex"
                   :class="item.active===valueIndex?'fontSize14 color333 active':'fontSize14 color333'"
                   @click="updateGoodsSpecificationActive(item,items,valueIndex)">{{ items.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="artificialAttributeListContainer" v-show="attributeTypeStatus===2">
        <div class="artificialAttributeTitle flexAndCenter">
          <div>{{ $fanyi('属性名称') }}</div>
          <div>{{ $fanyi('具体属性值') }}</div>
          <div>{{ $fanyi('操作') }}</div>
        </div>
        <div>
          <div v-for="(item,index) in artificialAttributeEditList" :key="index"
               class="artificialAttributeEditContainer flexAndCenter">
            <div class="artificialAttributeEditLeftContainer flexAndCenter">
              <el-input v-model="item.key" style="width: 200px;margin-right: 15px" size="small"></el-input>
              <el-input v-model="item.value" style="width: 296px;" size="small"></el-input>
            </div>
            <div class="artificialAttributeEditRightContainer flexAndCenter" style="margin-left: 10px;cursor: pointer"
                 @click="artificialAttributeEditList.splice(index,1)">
              <img src="../../../../../assets/7.png" alt="">
            </div>
          </div>
          <div class="addAttributeText fontSize14 flexAndCenter"
               @click="artificialAttributeEditList.push({key:'',value:''})"
               style="cursor: pointer">
            <img src="../../../../../assets/5.png" style="width:16px;margin-right:7px">
            {{ $fanyi("添加一行") }}
          </div>
        </div>
      </div>
    </div>
    <!--    类别-->
    <div class="borderBottom padding-bottom-20" v-if="inventoryTagOption.length>0">
      <div class="flexAndCenter tagListContainer" style="margin:20px 0">
        <div class="flexAndCenter">
          <div class="label" style="margin-right: 35px;text-align: left">{{ $fanyi("类别") }}:</div>
          <el-select v-model="tagValue" :placeholder="$fanyi('请选择')" @change="tagOptionChange">
            <el-option v-for="(item,index) in inventoryTagOption" :key="index"
                       :label="item.type_translate" :value="item.type">
            </el-option>
          </el-select>
        </div>
        <div class="oldTagBtn" @click="getOldTagList">{{ $fanyi("历史标签") }}</div>
      </div>
      <div class="flexAndCenter margin-bottom-20 goodsLink">
        <div class="label  margin-right-35">{{ $fanyi("FBA码") }}:</div>
        <el-input v-model="inventoryTagOption[tagIndex].no"
                  :placeholder="inventoryTagOption[tagIndex].type==='YAHU'?'検品コード':inventoryTagOption[tagIndex].type==='FBA'||inventoryTagOption[tagIndex].type==='ZOZO'?'FNSKU':'SKU'"
                  style="width: 500px;"/>
      </div>
      <div class="flexAndCenter goodsLink">
        <div class="label margin-right-35">{{ $fanyi("商品名称") }}:</div>
        <el-input v-model="inventoryTagOption[tagIndex].goods_no" style="width: 500px;"
                  :placeholder="inventoryTagOption[tagIndex].type==='FBA'||inventoryTagOption[tagIndex].type==='ZOZO'?'ASIN':'商品URL'"/>
      </div>
    </div>
    <!--    option-->
    <div class="borderBottom">
      <div class="flexAndCenter tagListContainer" style="margin:20px 0 0">
        <div class="flex">
          <el-popover trigger="hover" placement="top">
            <div style="width: 320px">
              <div style="margin-bottom: 20px">
                下記記載のサービスはあくまで基本的なもので、
              </div>
              <div style="margin-bottom: 20px">
                記載されていないサービスなど、新規需要などあれば担当者までご相談していただき、
              </div>
              <div>対応可能か個別で確認返答させていただきます。</div>
            </div>
            <img src="../../../../../assets/4.png" alt="" slot="reference">
          </el-popover>
          <div style="min-width:48px;margin:0 5px">option:</div>
          <div>
            <div class="optionListContainer">
              <div v-for="(item,index) in $parent.$parent.optionList" :key="index">
                <div v-if="item.checked" class="flexAndCenter optionContainer">
                  <input v-model="item.checked" style="margin-right:6px" type="checkbox"/>
                  <div class="optionLabel">{{ index + 1 }}）{{ item.name_translate }}</div>
                </div>
              </div>
            </div>
            <el-button style="margin-bottom: 20px" type="primary" @click="showGoodsOptionDialogVisible=true">
              {{ $fanyi("选择") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!--    管理番号-->
    <div>
      <div class="flexAndCenter margin-bottom-20 goodsLink" style="margin-top: 20px">
        <div class="label width71 margin-right-35">{{ $fanyi("管理番号") }}:</div>
        <el-input v-model="$parent.$parent.goodsInfo.for_sn" style="width: 500px"
                  :placeholder="$fanyi('请输入管理番号')"/>
      </div>
      <!--    备注-->
      <div class="flex margin-bottom-20 ">
        <div class="label margin-right-35" style="width:61px">{{ $fanyi("备注") }}:</div>
        <el-input type="textarea" style="width: 500px;" resize="none" :placeholder="$fanyi('输入备注')"
                  v-model="$parent.$parent.goodsInfo.note"/>
      </div>
      <div class="btnListContainer flexAndCenter">
        <div @click="$emit('closeEditGoodsDialogVisible',false)">{{ $fanyi("取消") }}</div>
        <div @click="submitGoodsInfo">{{ $fanyi("保存") }}</div>
      </div>
    </div>
    <!--    tag编辑-->
    <div class="dialogContainer">
      <el-dialog
          width="656px"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          append-to-body
          custom-class="oldTagDialogContainer"
          :title="$fanyi('历史标签')"
          :visible.sync="oldTagDialogVisible">
        <div class="selectTagBtnContainer flexAndCenter">
          <div :class="tagCheckedIndex===0?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=0">アマゾン倉庫
          </div>
          <div :class="tagCheckedIndex===1?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=1">楽天倉庫
          </div>
          <div :class="tagCheckedIndex===2?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=2">ヤマト倉庫
          </div>
          <div :class="tagCheckedIndex===3?'checkedTagTypeContainer':''" style="margin-right:8px"
               @click="tagCheckedIndex=3">その他
          </div>
        </div>
        <div>
          <div class="oldTagTableHeader flexAndCenter">
            <div class="tagType">{{ $fanyi("标签号") }}</div>
            <div :class="tagCheckedIndex===0?'tagType':'goodsName'">{{ $fanyi("商品码") }}</div>
            <div v-show="tagCheckedIndex===0" class="goodsName">{{ $fanyi("标签信息") }}</div>
            <div class="selectTag">{{ $fanyi("操作") }}</div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===0">
            <div v-for="(item,index) in FBAList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div :class="tagCheckedIndex===0?'tagType':'goodsName'">{{ item.goods_no }}</div>
              <div v-show="tagCheckedIndex===0" class="goodsName">{{ item.text_line_one }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="FBAAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===1">
            <div v-for="(item,index) in EMSSKUList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="EMSSKUAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===2">
            <div v-for="(item,index) in YAHUList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="YAHUAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
          <div class="oldTagTableFooter" v-show="tagCheckedIndex===3">
            <div v-for="(item,index) in otherList" :key="index" class="oldTagTableFooterLi flexAndCenter">
              <div class="tagType">{{ item.no }}</div>
              <div class="goodsName">{{ item.goods_no }}</div>
              <div class="selectTag flexAndCenter">
                <div @click="otherAffirm(index)">{{ $fanyi("选择") }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
      <editOption/>
    </div>
  </div>
</template>

<script>
import editOption from './option.vue'

export default {
  components: {
    editOption
  },
  data() {
    return {
      url: '',
      tagCheckedIndex: 0,
      oldTagDialogVisible: false,
      showGoodsOptionDialogVisible: false,
      attributeTypeStatus: 1,
      artificialAttributeEditList: [{
        key: '',
        value: ''
      }, {
        key: '',
        value: ''
      }],
      tagValue: '',
      tagIndex: 0,
      EMSSKUList: [],
      FBAList: [],
      YAHUList: [],
      otherList: [],
      inventoryTagOption: []
    }
  },
  created() {
    this.$api.getOrderTagList({}).then((res) => {
      res.data.forEach((item) => {
        item.no = '';
        item.goods_no = ''
      })
      this.inventoryTagOption = res.data;
      if (this.$parent.$parent.addGoods == false && this.$parent.$parent.goodsInfo.inventory_tag.length > 0) {
        this.$parent.$parent.goodsInfo.inventory_tag.forEach((item) => {
          this.inventoryTagOption.forEach((optionItem) => {
            if (optionItem.type === item.type) {
              this.tagValue = item.type;
              optionItem.no = item.no;
              optionItem.goods_no = item.goods_no;
            }
          })
        })
      }
    });
  },
  methods: {
    //图片上传成功
    handleAvatarSuccess(res, file) {
      this.$parent.$parent.goodsInfo.img_url = res.data;
      this.$forceUpdate();
      this.loading.close();
    },
    //图片上传前预览
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("写真上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    //根据链接获取商品详情
    getProductDetails() {
      let id = '';
      if (this.$parent.$parent.goodsInfo.id != undefined) {
        id = this.$fun.deepClone(this.$parent.$parent.goodsInfo.id);
      }
      let timestamp = Math.floor(new Date().valueOf() / 1000);
      let sign = this.$fun.setToolValue(timestamp);
      this.$api.EuropeKeywordSearchProduct({keywords: this.url, timestamp: timestamp, sign: sign}).then((result) => {
            if (result.code != 0) {
              this.$message.error(result.msg);
              return
            }
            let goodsProductDetails = {};
            goodsProductDetails.goodsInfo = {};
            goodsProductDetails.goods_title = result.data.result.titleC;
            goodsProductDetails.goods_id = result.data.result.goodsId;
            goodsProductDetails.shop_type = result.data.result.fromPlatform;
            goodsProductDetails.shop_id = result.data.result.shopId;
            goodsProductDetails.shop_name = result.data.result.shopName;
            goodsProductDetails.num = 1;
            goodsProductDetails.img_url = result.data.result.images[0]
            let goodsInventoryOptionStr = '';
            result.data.result.goodsInfo.specification.forEach((item, specificationIndex) => {
              foo:for (let i = 0; i < item.valueC.length; i++) {
                if (i === 0 && specificationIndex !== result.data.result.goodsInfo.specification.length - 1) {
                  item.active = 0;
                  goodsInventoryOptionStr += item.valueC[i].name + "㊖㊎";
                }
                if (specificationIndex === result.data.result.goodsInfo.specification.length - 1) {
                  goodsInventoryOptionStr += `${item.valueC[i].name}`;
                  item.active = 0;
                  for (let j = 0; j < result.data.result.goodsInfo.goodsInventory.length; j++) {
                    if (result.data.result.goodsInfo.goodsInventory[j].keyC === goodsInventoryOptionStr) {
                      result.data.result.goodsInfo.goodsInventory[j].valueC[0].name = item.valueC[i].name;
                      if (item.valueC[i].picUrl !== "") {
                        result.data.result.goodsInfo.goodsInventory[j].valueC[0].picUrl = item.valueC[i].picUrl;
                      }
                      goodsProductDetails.price = result.data.result.goodsInfo.goodsInventory[j].valueC[0].price;
                      break foo;
                    }
                  }
                  goodsInventoryOptionStr = goodsInventoryOptionStr.substr(0, goodsInventoryOptionStr.length - item.valueC[i].name.length);
                }
              }
            });
            goodsProductDetails.goodsInfo = result.data.result.goodsInfo;
            if (JSON.stringify(this.$parent.$parent.goodsInfo) == '{}') {
              this.$parent.$parent.goodsInfo = goodsProductDetails;
            } else {
              this.$parent.$parent.goodsInfo.goods_title = result.data.result.titleC;
              this.$parent.$parent.goodsInfo.goods_id = result.data.result.goodsId;
              this.$parent.$parent.goodsInfo.shop_type = result.data.result.fromPlatform;
              this.$parent.$parent.goodsInfo.shop_id = result.data.result.shopId;
              this.$parent.$parent.goodsInfo.shop_name = result.data.result.shopName;
              this.$parent.$parent.goodsInfo.img_url = result.data.result.images[0];
              this.$parent.$parent.goodsInfo.goodsInfo = result.data.result.goodsInfo;
              this.$parent.$parent.goodsInfo.price = goodsProductDetails.price;
            }
            if (id != '') {
              this.$parent.$parent.goodsInfo.id = id;
            }
            this.$forceUpdate();
          }
      )
    },
    //更新选中属性
    updateGoodsSpecificationActive(item, items, index) {
      item.active = index;
      this.$forceUpdate();
      if (items.picUrl !== '') {
        this.$parent.$parent.goodsInfo.img_url = items.picUrl;
      }
      let specificationStr = '';
      this.$parent.$parent.goodsInfo.goodsInfo.specification.forEach((specificationItem) => {
        specificationStr += specificationItem.valueC[specificationItem.active].name + "㊖㊎";
      })
      specificationStr = specificationStr.substr(0, specificationStr.length - 2);
      this.$parent.$parent.goodsInfo.goodsInfo.goodsInventory.forEach((goodsInventoryItem) => {
        if (goodsInventoryItem.keyC === specificationStr) {
          this.$parent.$parent.goodsInfo.price = goodsInventoryItem.valueC[0].price;
        }
      })
    },
    //EMSSKU选择
    EMSSKUAffirm(index) {
      this.inventoryTagOption.forEach((item) => {
        if (item.type === this.EMSSKUList[index].type) {
          item.no = this.EMSSKUList[index].no;
          item.goods_no = this.EMSSKUList[index].goods_no;
        }
      })
    },
//FBA选择
    FBAAffirm(index) {
      this.inventoryTagOption.forEach((item) => {
        if (item.type === this.FBAList[index].type) {
          item.no = this.FBAList[index].no;
          item.goods_no = this.FBAList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
    YAHUAffirm(index) {
      this.inventoryTagOption.forEach((item) => {
        if (item.type === this.YAHUList[index].type) {
          item.no = this.YAHUList[index].no;
          item.goods_no = this.YAHUList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
    otherAffirm(index) {
      this.inventoryTagOption.forEach((item) => {
        if (item.type === this.otherList[index].type) {
          item.no = this.otherList[index].no;
          item.goods_no = this.otherList[index].goods_no;
          this.oldTagDialogVisible = false;
        }
      })
    },
//更新标签类型
    tagOptionChange() {
      this.inventoryTagOption.forEach((item, index) => {
        if (this.tagValue === item.type) {
          this.tagIndex = index;
        }
      })
    },
//商品详情页跳转
    productDetailsPageJump() {
      if (JSON.stringify(this.$parent.$parent.goodsInfo) !== '{}') {
        this.$fun.toCommodityDetails(this.$parent.$parent.goodsInfo.goods_id, this.$parent.$parent.goodsInfo.shop_type)
      }
    },
    //商品库追加或修改
    submitGoodsInfo() {
      let arr = [];
      let detail = [];
      let option = [];
      let inventory_tag = [];
      let detail_edit = [];
      let sku_id = '';
      let spec_id = '';
      let str = '';
      let status = false;
      this.artificialAttributeEditList.forEach((item) => {
        if (item.key !== '' && item.value !== '') {
          detail_edit.push(item);
        }
      })
      this.$parent.$parent.optionList.forEach((item) => {
        if (item.checked === true) {
          item.num = 1;
          option.push(item)
        }
      })
      this.$parent.$parent.goodsInfo.goodsInfo.specification.forEach((item) => {
        if (item.active == undefined) {
          item.active = 0;
        }
        str += item.valueC[item.active].name + "㊖㊎"
        detail.push({
          key: item.keyC,
          value: item.valueC[item.active].name,
        })
      })
      str = str.substr(0, str.length - 2);
      this.$parent.$parent.goodsInfo.goodsInfo.goodsInventory.forEach((item) => {
        if (item.keyC === str) {
          sku_id = item.valueC[0].skuId;
          spec_id = item.valueC[0].specId;
        }
      })
      this.inventoryTagOption.forEach((item) => {
        if (item.goods_no !== '' || item.no !== '') {
          inventory_tag.push({
            type: item.type,
            no: item.no,
            goods_no: item.goods_no,
            type_translate: item.type_translate
          })
        }
      })
      var sarr = detail_edit.sort();
      for (let i = 0; i < sarr.length; i++) {
        if (i !== sarr.length - 1) {
          if (sarr[i].key == sarr[i + 1].key) {
            status = true;
            break;
          }
        }
      }
      if (status === true) {
        this.$message.error('チェック便宜上、属性制限しております。必要な場合では担当者までご連絡くださいませ。');
        return
      }
      arr.push({
        id: this.$parent.$parent.addGoods == true ? undefined : this.$parent.$parent.goodsInfo.id,//判断是否是商品库追加
        shop_type: this.$parent.$parent.goodsInfo.shop_type,
        shop_id: this.$parent.$parent.goodsInfo.shop_id,
        goods_id: this.$parent.$parent.goodsInfo.goods_id,
        num: this.$parent.$parent.goodsInfo.num,
        price: this.$parent.$parent.goodsInfo.price,
        sku_id: detail_edit.length == 0 ? sku_id : '',
        spec_id: detail_edit.length == 0 ? spec_id : '',
        shop_name: this.$parent.$parent.goodsInfo.shop_name,
        goods_title: this.$parent.$parent.goodsInfo.goods_title,
        img_url: this.$parent.$parent.goodsInfo.img_url,
        detail: JSON.stringify(detail),
        option: option.length > 0 ? JSON.stringify(option) : '[]',
        note: this.$parent.$parent.goodsInfo.note,
        for_sn: this.$parent.$parent.goodsInfo.for_sn,
        detail_edit: detail_edit.length > 0 && this.attributeTypeStatus === 2 ? JSON.stringify(detail_edit) : undefined,
        inventory_tag: inventory_tag,
        copy_from_id: this.$parent.$parent.goodIndex != null ? this.$parent.$parent.goodIndex : undefined
      })
      this.$api.favoriteInventoryBatchSave({data: arr}).then((res) => {
        this.$parent.$parent.optionList.forEach((item) => {
          item.checked = false
        })
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.$message.success(this.$fanyi(res.msg))
        this.artificialAttributeEditList = [{
          key: '',
          value: ''
        }, {
          key: '',
          value: ''
        }];
        this.attributeTypeStatus = 0;
        this.$parent.$parent.goodsInfo = {};
        this.$parent.$parent.status = false;
        this.$emit('closeEditGoodsDialogVisible', false)
      });
    },
    //获取历史标签
    getOldTagList() {
      if (JSON.stringify(this.$parent.$parent.goodsInfo) !== '{}') {
        this.$api.goodsTagLogs({
          goods_id: this.$parent.$parent.goodsInfo.goods_id,
          shop_type: this.$parent.$parent.goodsInfo.shop_type
        }).then((res) => {
          this.EMSSKUList = [];
          this.FBAList = [];
          this.YAHUList = [];
          this.otherList = [];
          res.data.forEach((item) => {
            switch (item.type) {
              case 'FBA':
                this.FBAList.push(item);
                break;
              case 'LETIAN':
                this.EMSSKUList.push(item);
                break;
              case 'YAHU':
                this.YAHUList.push(item);
                break;
              case '其他':
                this.otherList.push(item);
                break;
            }
          })
          this.oldTagDialogVisible = true
        });
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.editGoodsContainer {
  margin-top: 40px;

  .borderBottom {
    border-bottom: 1px solid #DFDFDF;
  }

  .priceContainer {
    width: 180px;
    height: 30px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding-left: 9px;
  }

  .label {
    font-size: 14px;
    color: #333333;
    text-align: right;
    width: 75px !important;
    min-width: 75px;
  }

  .width71 {
    width: 71px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }

  .margin-right-35 {
    margin-right: 35px;
  }

  .btnListContainer {
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #fff;
      border: 1px solid #B4272B;
      border-radius: 8px;
      font-size: 14px;
      color: #B4272B;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
    }

    div:last-child {
      color: #fff;
      background: #B4272B;
      margin-left: 26px;
    }
  }

  .goodsLink, .goodPrice {
    /deep/ .el-input__inner {
      width: 500px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }
  }

  .goodsTitle {
    width: 500px;
    height: 70px;
    background: #F9F9F9;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding: 8px 3px 0 9px;
    font-size: 14px;
    color: #333333;
  }

  .goodsImage {
    width: 116px;
    height: 116px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    position: relative;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .updateGoodsImageIcon {
    width: 26px;
    height: 16px;
    background: #B4272B;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
  }

  .goodsAttributeEditTypeContainer {
    margin-top: 36px;

    .el-button + .el-button {
      margin-left: 26px;
    }
  }

  .goodsDetailContainer {
    .keyContainer {
      margin-top: 20px;
      font-size: 14px;
      color: #333333;
      text-align: right;
      width: 75px !important;
      min-width: 75px;
    }

    .valueAttributeListContainer {
      display: flex;
      flex-wrap: wrap;

      div {
        padding: 8px 16px;
        border-radius: 3px;
        box-sizing: border-box;
        border: 1px solid #DFDFDF;
        margin: 20px 0 0 20px;
        cursor: pointer;
      }

      .active {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }

  .artificialAttributeTitle {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #F0F0F0;
    margin-top: 20px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: #333;
    font-size: 14px;

    div:first-child {
      width: 220px;
    }

    div:nth-child(2n) {
      width: 316px;
    }

    div:last-child {
      width: 60px;
    }
  }

  .artificialAttributeEditContainer {
    border-bottom: 1px solid #DFDFDF;

    .artificialAttributeEditLeftContainer {
      padding: 10px 8px;
    }

    .artificialAttributeEditRightContainer {
      width: 60px;
      justify-content: center;
    }
  }

  .tagListContainer {
    justify-content: space-between;

    /deep/ .el-input__inner {
      width: 135px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
      line-height: 30px;
    }

    /deep/ .el-input__icon {
      line-height: 30px;
    }

    .oldTagBtn {
      width: 100px;
      height: 30px;
      background: #B4272B;
      border-radius: 3px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      color: #FFFFFF;
      font-size: 14px;
    }

    .optionListContainer {
      display: flex;
      flex-wrap: wrap;
      width: 600px;

      .optionContainer {
        margin-right: 13px;
        margin-bottom: 21px;

        .optionLabel {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  .dialogContainer {
    /deep/ .el-dialog {
      padding: 30px;
    }

    /deep/ .el-dialog__header {
      padding: 0;
      display: flex;
      justify-content: center;

      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        color: #333;
        padding-bottom: 7px;
        border-bottom: 4px solid #B4272B;
        text-align: center;
      }

      .el-dialog__headerbtn {
        top: 30px;
        font-size: 19px;

        .el-dialog__close {
          color: #B4272b;
        }
      }
    }

    .selectTagBtnContainer {
      margin-bottom: 20px;

      div {
        width: 120px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
        font-size: 14px;
        color: #333;
        cursor: pointer;
        line-height: 30px;
        text-align: center;
      }

      .checkedTagTypeContainer {
        color: #fff;
        background: #B4272B;
        border: 1px solid #B4272B;
      }
    }

    .tagType {
      width: 160px;
    }

    .goodsName {
      width: 336px;
      line-height: 20px !important;
    }

    .selectTag {
      width: 100px;
      justify-content: center;

      div {
        width: 60px;
        height: 30px;
        background: #B4272B;
        border-radius: 3px;
        line-height: 30px !important;
        text-align: center !important;
        color: #fff !important;
        font-size: 14px !important;
        justify-content: center;
        cursor: pointer;
      }
    }

    .oldTagTableHeader {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #F0F0F0;
      height: 46px;

      div {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }

    .oldTagTableFooterLi {
      height: 50px;
      border-bottom: 1px solid #DFDFDF;

      div {
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .addAttributeText {
    margin-top: 12px;
    text-decoration: underline;
    color: #50C136;
    cursor: pointer;
  }
}
</style>
